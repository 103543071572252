/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {inject, Injectable} from '@angular/core';
import {Favorit} from '@dv/kitadmin/models';
import {FavoritenService as FavoritenApi} from '@dv/shared/backend/api/favoriten.service';
import type {IPersistable, NamedEntityType} from '@dv/shared/code';
import {checkPresent, DvbRestUtil, DvbUtil} from '@dv/shared/code';
import {adapt} from '@state-adapt/angular';
import {createAdapter, joinAdapters} from '@state-adapt/core';
import {booleanAdapter} from '@state-adapt/core/adapters';
import {Source, splitRequestSources, toRequestSource} from '@state-adapt/rxjs';
import type {Observable} from 'rxjs';
import {concatMap, filter, map, merge, switchMap} from 'rxjs';

const path = 'favoriten';

interface FavoritState {
    isLoading: boolean;
    favoriten: Readonly<Favorit>[];
}

const initialState = (): FavoritState => ({
    isLoading: true,
    favoriten: [],
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createPersistableAdapter = <T extends IPersistable>() => createAdapter<T[]>()({
    removeItem: (state, item) => {
        return state.filter(i => i.id !== item.id);
    },
    selectors: {
        ids: state => state.map(DvbUtil.mapToId),
    },
});

const favoritenAdapter = createPersistableAdapter<Favorit>();
const adapter = joinAdapters<FavoritState>()({
    isLoading: booleanAdapter,
    favoriten: favoritenAdapter,
})({
    setFavoriten: {
        favoriten: favoritenAdapter.set,
        isLoading: booleanAdapter.setFalse,
    },
})();

@Injectable({
    providedIn: 'root',
})
export class FavoritService {
    private readonly favoritenApi = inject(FavoritenApi);

    public readonly create$ = new Source<Favorit>('[Favoriten] create');
    public readonly remove$ = new Source<Favorit>('[Favoriten] remove');

    private store = adapt(initialState(), {
        path,
        adapter,
        sources: store => {
            const createRequest = splitRequestSources('[Favoriten API] create', this.create$.pipe(
                concatMap(({payload}) => this.favoritenApi.create$({jaxFavorit: payload.toRestObject()}).pipe(
                    toRequestSource('[Favoriten API] create'))),
            ));

            const removeRequest = splitRequestSources('[Favoriten API] remove', this.remove$.pipe(
                map(({payload}) => checkPresent(payload.id)),
                // eslint-disable-next-line no-underscore-dangle
                concatMap(id => this.favoritenApi._delete$({favoritId: id}).pipe(
                    toRequestSource('[Favoriten API] remove'),
                )),
            ));

            const needData$ = store.state$.pipe(
                filter(s => s.isLoading && s.favoriten.length === 0),
            );

            const getAllRequest = splitRequestSources('[Favoriten API] getAll',
                merge(needData$, createRequest.success$).pipe(
                    switchMap(() => this.favoritenApi.getAll$().pipe(
                        map((response: any) => DvbRestUtil.transformArray(response.favoriten, Favorit)),
                        toRequestSource('[Favoriten API] getAll')),
                    ),
                ));

            return {
                setIsLoadingTrue: this.create$,
                setIsLoadingFalse: createRequest.error$,
                setFavoriten: getAllRequest.success$,
                removeFavoritenItem: this.remove$,
                reset: [getAllRequest.error$, removeRequest.error$],
                noop: [removeRequest.success$, createRequest.success$],
            };
        },
    });

    public isLoading$(): Observable<boolean> {
        return this.store.isLoading$;
    }

    public getAll$(): Observable<Readonly<Favorit>[]> {
        return this.store.favoriten$;
    }

    public getByType$(...types: NamedEntityType[]): Observable<Readonly<Favorit>[]> {
        return this.store.favoriten$.pipe(
            map(favoriten => favoriten.filter(f => types.includes(f.namedEntity.dtype!))),
        );
    }
}
